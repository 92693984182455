import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";
import Marcas from "./routes/marcas";
import Organizaciones from "./routes/organizaciones";
import Apikeys from "./routes/apikeys";
import Widgets from "./routes/widgets";
import ExtraPages from "./routes/extraPages";
// import Tour from "../components/Tour/index";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION
} from "constants/ActionTypes";
import ColorOption from "containers/Customizer/ColorOption";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";
import "assets/fonts/cemtrik-icons-v1.0/style.css";

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour/> */}

        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/marcas`} component={Marcas} />
                <Route
                  path={`${match.url}/organizaciones`}
                  component={Organizaciones}
                />
                <Route path={`${match.url}/apikeys`} component={Apikeys} />
                <Route path={`${match.url}/widgets`} component={Widgets} />

                <Route
                  path={`${match.url}/extra-pages`}
                  component={ExtraPages}
                />
                <Route
                  path={`${match.url}/to-do`}
                  component={asyncComponent(() =>
                    import("./routes/todo/basic/index")
                  )}
                />
                <Route
                  path={`${match.url}/to-do-redux`}
                  component={asyncComponent(() =>
                    import("./routes/todo/redux/index")
                  )}
                />
                <Route
                  path={`${match.url}/mail`}
                  component={asyncComponent(() =>
                    import("./routes/mail/basic/index")
                  )}
                />
                <Route
                  path={`${match.url}/mail-redux`}
                  component={asyncComponent(() =>
                    import("./routes/mail/redux/index")
                  )}
                />
                <Route
                  path={`${match.url}/chat`}
                  component={asyncComponent(() =>
                    import("./routes/chatPanel/basic/index")
                  )}
                />
                <Route
                  path={`${match.url}/chat-redux`}
                  component={asyncComponent(() =>
                    import("./routes/chatPanel/redux/index")
                  )}
                />
                <Route
                  path={`${match.url}/contact`}
                  component={asyncComponent(() =>
                    import("./routes/contact/basic/index")
                  )}
                />
                <Route
                  path={`${match.url}/contact-redux`}
                  component={asyncComponent(() =>
                    import("./routes/contact/redux/index")
                  )}
                />
                <Route
                  path={`${match.url}/extra-pages`}
                  component={ExtraPages}
                />
                <Route
                  component={asyncComponent(() =>
                    import("app/routes/extraPages/routes/404")
                  )}
                />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
        <ColorOption />
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
