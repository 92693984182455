import React from "react";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { auth } from "../firebase/firebase";
import { validateForms } from "../actions/Helpers";

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      repeat_password: "",
      isExpired: false,
      alert: false,
      alertData: {},
      rules: [
        {
          field: "password",
          label: "Clave",
          type: "password",
          required: true,
          min: 6
        },
        {
          field: "repeat_password",
          label: "Repetir clave",
          type: "equal",
          equal_to: "password",
          required: true,
          min: 6
        }
      ],
      errors: {
        password: "",
        repeat_password: ""
      }
    };
  }

  componentDidMount() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    //console.log(auth.createUserWithEmailAndPassword());
    //se valida el código enviado en el token
    auth
      .verifyPasswordResetCode(this.props.match.params.oobCode)
      .then(email => {
        //si el código es correcto o no ha expirado se setea el estado del email
        this.setState({
          email: email,
          isExpired: false
        });
      })
      .catch(error => {
        this.setState({
          email: "",
          isExpired: true
        });
        //console.log(error);
      });
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value
      //errors, [name]: value
    });
  };

  validateForm = () => {
    //se llama la función global para validar formularios
    let results = validateForms(this.state);
    //la función global validateForms() retorna los errores y si el formulario pasa la validación
    this.setState({
      errors: results[0]
    });
    return results[1];
  };

  actionResetPassword = e => {
    const { password } = this.state;
    e.preventDefault();
    //JULIAN: si el formulario pasa la validación
    if (this.validateForm()) {
      //se cambia la clave del usuario usando el método confirmPasswordReset
      auth
        .confirmPasswordReset(this.props.match.params.oobCode, password)
        .then(resp => {
          //si cambia la clave correctamente se redirecciona al login
          this.props.history.push("/signin/resetpassword");
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  render() {
    const { errors } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Admin Cemtrik">
              <img
                className="img_login"
                src={require("assets/images/logo.png")}
                alt="Admin Cemtrik"
                title="Admin Cemtrik"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Cambiar clave</h1>
            </div>

            <div className="app-login-form">
              {!this.state.isExpired ? (
                <form
                  onSubmit={this.actionResetPassword}
                  ref="resetPasswordForm"
                  noValidate
                >
                  <FormControl
                    className="w-100 mb-2"
                    error={errors.password.length > 0 ? true : false}
                  >
                    <TextField
                      error={errors.repeat_password.length > 0 ? true : false}
                      type="password"
                      name="password"
                      label="Clave"
                      value={this.state.password}
                      onChange={this.updateInput}
                      onBlur={this.validateForm}
                      margin="normal"
                      fullWidth
                      noValidate
                    />
                    {errors.password.length > 0 && (
                      <FormHelperText>{errors.password}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    className="w-100 mb-2"
                    error={errors.repeat_password.length > 0 ? true : false}
                  >
                    <TextField
                      error={errors.repeat_password.length > 0 ? true : false}
                      type="password"
                      name="repeat_password"
                      label="Repetir Clave"
                      value={this.state.repeat_password}
                      onChange={this.updateInput}
                      onBlur={this.validateForm}
                      margin="normal"
                      fullWidth
                      noValidate
                    />
                    {errors.repeat_password.length > 0 && (
                      <FormHelperText>{errors.repeat_password}</FormHelperText>
                    )}
                  </FormControl>
                  <div className="mb-3 align-items-center justify-content-between text-center">
                    <button type="submit" className="btn btn-primary">
                      Cambiar clave
                    </button>
                  </div>
                </form>
              ) : (
                <p>
                  <br />
                  <br />
                  Esta solicitud es inválida o el tiempo para cambiar la clave
                  ha expirado. Solicite nuevo cambio de clave o comuníquese con{" "}
                  <Link to="mailto:soporte@valorpercibido.com">
                    soporte@valorpercibido.com
                  </Link>
                  .<br />
                  <br />
                  <br />
                </p>
              )}
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

export default ResetPassword;
