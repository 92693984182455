const {
  ACCION,
  cambiarPosicionElementos,
  condicionParaCambiarPosicion,
} = require('./cambiarPosicionElementos.js');

const actualizarDocumentos = sortedData => sortedData.elementos.map(({ id, ref, ...elemento }, i) => {
  if (sortedData.pendienteActualizar[i]) {
    // set new data of firestore element based on firestore element reference
    ref.set(elemento);
  }
  return { id, ...elemento };
});

const cambiarPosicionElementosColeccionFirestore = (
  arregloDeDocumentosDesdeReferenciaColeccionFirestore, 
  idDocumentoFirestoreParaActualizar, 
  accion
) => {
  const sortedData = cambiarPosicionElementos(
    arregloDeDocumentosDesdeReferenciaColeccionFirestore,
    idDocumentoFirestoreParaActualizar,
    condicionParaCambiarPosicion(accion)
  );
  actualizarDocumentos(sortedData);
  return sortedData.elementos;
}

export {
  ACCION,
  cambiarPosicionElementosColeccionFirestore,
};