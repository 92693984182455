import uuid from "uuid";

const generateRandom = length => {
  const randomString = uuid.v4();
  if (length) {
    return randomString
      .toString()
      .replace("-", "")
      .substring(0, length);
  }
  return randomString;
};

export { generateRandom };
