import firebase from "firebase";
//se valida si la app está corriendo en modo pruebas o producción
import configuracion from "../config";
//console.log(window.location.hostname);
// Config Admin
const config_admin = {
  apiKey: configuracion.api_key_firebase_admin,
  authDomain: configuracion.auth_domain_admin,
  projectId: configuracion.projectId_admin,
  storageBucket: configuracion.storageBucket_admin
};

firebase.initializeApp(config_admin);
const auth = firebase.auth();
const storage = firebase.storage();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const firestore = firebase.firestore();
const functions = firebase.functions();

//firestore.settings({timestampsInSnapshots:true})

//Config worlddbCemtrik
const conf_worlddbCemtrik = {
  apiKey: configuracion.api_key_firebase_worldDB,
  authDomain: configuracion.auth_domain_worldDB,
  projectId: configuracion.projectId_worldDB
};

// Inicio de aplicación admin-cemtrik
var worlddbCemtrik = firebase.initializeApp(
  conf_worlddbCemtrik,
  configuracion.projectId_worldDB
);

const auth_worlddbCemtrik = worlddbCemtrik.auth();
const firestore_worlddbCemtrik = worlddbCemtrik.firestore();

export {
  auth,
  storage,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  firestore,
  firebase,
  functions,
  auth_worlddbCemtrik,
  firestore_worlddbCemtrik
};
