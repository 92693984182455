export function validateForms(states, extra_rules = "", extra_errors = "") {
  //se traen las reglas y el estado errors para los mensajes de errores
  //Se valida si la funcion tiene reglas extras, es decir el componente padre tiene mas de un formulario
  let rules = states[extra_rules] ? states[extra_rules] : states.rules;
  let errors = states[extra_errors] ? states[extra_errors] : states.errors;
  let validForm = true;
  //console.log(rules);
  //se recorren las reglas del formulario para validar cada tipo de campo
  rules.forEach(rule => {
    //si el campo es requerido se debe validar que no esté vacío el campo

    if (rule.required) {
      //console.log(rule);
      //se compara dinámicamente con el valor actual del estado (del campo)
      if (states[rule.field] === "") {
        //console.log(rule);
        errors[rule.field] = [rule.label] + " es requerido";
        validForm = false;
      } else {
        errors[rule.field] = "";
        //validForm = true;
      }
    }

    //si el campo tiene un min_length se valida que cumpla ese mínimo de caracteres
    if (rule.min) {
      if (states[rule.field].length < rule.min) {
        errors[rule.field] =
          [rule.label] + " debe contener mínimo " + rule.min + " caracteres.";
        validForm = false;
      } else {
        errors[rule.field] = "";
      }
    }
    //si el campo tiene un equal_to se valida que ese campo sea igual al que esté en equal_to
    if (rule.equal_to) {
      if (states[rule.field] !== states[rule.equal_to]) {
        errors[rule.field] = "Los campos no coinciden";
        validForm = false;
      } else {
        errors[rule.field] = "";
      }
    }
  });

  return [errors, validForm];
}

export function testStates(states) {
  console.log(states);
}
