import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setDateFormat, newAttributes } from "../actions/Helpers";
import { auth, firestore } from "../firebase/firebase";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn
} from "actions/Auth";
var ip = require("ip");

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      hits: [],
      isLoading: false
    };
    //this.setStateHandler = this.setStateHandler.bind(this);
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }

    //if (this.props.authUser !== null) {
    if (auth.currentUser) {
      //Como obtener el browser
      let browser = "";
      if (
        (navigator.userAgent.indexOf("Opera") ||
          navigator.userAgent.indexOf("OPR")) !== -1
      ) {
        browser = "Opera";
      } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
        browser = "Chrome";
      } else if (navigator.userAgent.indexOf("Safari") !== -1) {
        browser = "Safari";
      } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
        browser = "Firefox";
      } else if (
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        !!document.documentMode === true
      ) {
        //IF IE > 10
        browser = "IE";
      } else {
        browser = "unknown";
      }

      firestore
        .collection("ingresos")
        .add({
          nombres: newAttributes().nombres,
          correo: newAttributes().correo,
          app: "Admin-cemtrik",
          estado_registro: true,
          estado_eliminado: false,
          creado_por: newAttributes().correo,
          fecha_creacion: setDateFormat(new Date()),
          modificado_por: null,
          fecha_eliminacion: null,
          fecha_modificacion: null,
          eliminado_por: null,
          nombre_creadoPor: newAttributes().correo,
          nombre_eliminadoPor: null,
          nombre_modificadoPor: null,
          browser: browser,
          sistema_operativo: window.navigator.platform,
          ip: ip.address()
        })
        .then(() => {
          //console.log(ingresoRef.id);
          this.props.history.push("/app/organizaciones/organizacion");
          // this.showAlert('success', 'La ruta ha sido creada con éxito');
        })
        .catch(error => {
          console.log(error);
          //this.showAlert('danger', 'La ruta NO ha sido creada con éxito');
        });
      // User is signed in.
    }
  }

  render() {
    const { email, password } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Jambo">
              <img
                className="img_login"
                src={require("assets/images/logo.png")}
                alt="jambo"
                title="jambo"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Iniciar Sesión</h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label="Correo"
                    fullWidth
                    onChange={event =>
                      this.setState({ email: event.target.value })
                    }
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label="Contraseña"
                    fullWidth
                    onChange={event =>
                      this.setState({ password: event.target.value })
                    }
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      className="button_inicio"
                      onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userSignIn({ email, password });
                      }}
                      variant="contained"
                    >
                      Iniciar Sesión
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {showMessage && NotificationManager.error(alertMessage)}
        {this.props.match.params.mode &&
        this.props.match.params.mode === "resetpassword"
          ? NotificationManager.success("Su clave ha sido cambiada con éxito")
          : ""}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(SignIn);
