import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Marcas = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/marcas`} />
      <Route
        path={`${match.url}/marca/:id_organizacion`}
        component={asyncComponent(() => import("./routes/Marca"))}
      />
      <Route
        path={`${match.url}/experiencias/:id_organizacion/:id_marca`}
        component={asyncComponent(() => import("./routes/Experiencias"))}
      />
      <Route
        path={`${match.url}/puntos/:id_organizacion/:id_marca`}
        component={asyncComponent(() => import("./routes/Puntos"))}
      />
      <Route
        path={`${match.url}/atributos/:id_organizacion/:id_marca`}
        component={asyncComponent(() => import("./routes/Atributos"))}
      />
      <Route
        path={`${match.url}/elementos/:id_organizacion/:id_marca/:id_atributo`}
        component={asyncComponent(() => import("./routes/Elementos"))}
      />
      <Route
        path={`${match.url}/proyectos/:id_organizacion/:id_marca`}
        component={asyncComponent(() => import("./routes/Proyectos"))}
      />
      <Route
        path={`${match.url}/instrumentos/:id_organizacion/:id_marca/:id_proyecto`}
        component={asyncComponent(() => import("./routes/Instrumentos"))}
      />
      <Route
        path={`${match.url}/preguntas/:id_organizacion/:id_marca/:id_proyecto/:id_instrumento`}
        component={asyncComponent(() => import("./routes/Preguntas"))}
      />
      <Route
        path={`${match.url}/procesos/:id_organizacion/:id_marca/:id_proyecto`}
        component={asyncComponent(() => import("./routes/Procesos"))}
      />
      <Route
        path={`${match.url}/periodos/:id_organizacion/:id_marca`}
        component={asyncComponent(() => import("./routes/Periodos"))}
      />
      <Route
        path={`${match.url}/momentos/:id_organizacion/:id_marca/:id_experiencia`}
        component={asyncComponent(() => import("./routes/Momentos"))}
      />
      <Route
        path={`${match.url}/canales/:id_organizacion/:id_marca/:id_experiencia`}
        component={asyncComponent(() => import("./routes/Canales"))}
      />
      <Route
        path={`${match.url}/acciones/:id_organizacion/:id_marca/:id_experiencia`}
        component={asyncComponent(() => import("./routes/Acciones"))}
      />
      <Route
        path={`${match.url}/tramos/:id_organizacion/:id_marca/:id_experiencia`}
        component={asyncComponent(() => import("./routes/Tramos"))}
      />
      <Route
        path={`${match.url}/variables/:id_organizacion/:id_marca/:id_experiencia`}
        component={asyncComponent(() => import("./routes/Variables"))}
      />
      <Route
        path={`${match.url}/puntosdecontacto/:id_organizacion/:id_marca/:id_experiencia`}
        component={asyncComponent(() => import("./routes/PuntosDeContacto"))}
      />
      <Route
        path={`${match.url}/indicadores/:id_organizacion/:id_marca/:id_experiencia`}
        component={asyncComponent(() => import("./routes/Indicadores"))}
      />

      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default Marcas;
