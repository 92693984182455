/**************************************************************************
 * constantes para determinar las posibles acciones a realizar
 * cuando se llama a cambiarPosicionElementos
 **************************************************************************/
const ACCION = {
  MOVER_ELEMENTO_HACIA_ARRIBA: "MOVER_ELEMENTO_HACIA_ARRIBA",
  MOVER_ELEMENTO_HACIA_ABAJO: "MOVER_ELEMENTO_HACIA_ABAJO",
  POR_DEFECTO: "POR_DEFECTO"
};

/**************************************************************************
 * condiciones para para validar cambio de posición
 **************************************************************************/

/**
 * Valida si el id es el mismos del elemento firebase recibido
 * @param {string} id Id del elemento que intercambiará posición
 * @param {Object} elemento Objeto firebase para evaluar su cambio de posición
 */
const esElElementoACambiarPosicion = (id, elemento) => id === elemento.id;

/**
 * Valida si el elemento del arreglo, será el que intercambiará posición hacia arriba (intercambio con el elemento anterior del arreglo)
 * @param {string} id_elemento Id del elemento que intercambiará posición
 * @param {Array} arreglo arreglo con elementos firebase que se actualizará su posición
 * @param {number} indice índice del elemento del arreglo que se evaluará si aplica para cambiar posición
 */
const cumpleCondicionMoverElementoHaciaArriba = (
  id_elemento,
  arreglo,
  indice
) => indice > 0 && esElElementoACambiarPosicion(id_elemento, arreglo[indice]);

/**
 * Valida si el elemento del arreglo, será el que intercambiará posición hacia abajo (intercambio con el elemento posterior del arreglo)
 * @param {string} id_elemento Id del elemento que intercambiará posición
 * @param {Array} arreglo arreglo con elementos firebase que se actualizará su posición
 * @param {number} indice índice del elemento del arreglo que se evaluará si aplica para cambiar posición
 */
const cumpleCondicionMoverElementoHaciaAbajo = (id_elemento, arreglo, indice) =>
  indice > 0 && esElElementoACambiarPosicion(id_elemento, arreglo[indice - 1]);

/**
 * devuelve que una condición que nunca se cumple (false -> boolean)
 */
const nuncaSeCumpleCondicionParaCambiarPosicion = (/* id_elemento, arreglo, indice */) =>
  false;

/**
 * Devuelve una condición que valida si el elemento cumple los requisitos para intercambiar posición
 * @param {string} accion Acción que se realizará al actualizar posición en un arreglo
 */
const condicionParaCambiarPosicion = accion => {
  switch (accion) {
    case ACCION.MOVER_ELEMENTO_HACIA_ARRIBA:
      return cumpleCondicionMoverElementoHaciaArriba;

    case ACCION.MOVER_ELEMENTO_HACIA_ABAJO:
      return cumpleCondicionMoverElementoHaciaAbajo;

    case ACCION.POR_DEFECTO:
    default:
      return nuncaSeCumpleCondicionParaCambiarPosicion;
  }
};

/**************************************************************************
 * Lógica de intercambio de posiciones
 **************************************************************************/

/**
 * Cambia la posición de los elementos del arreglo y actualiza la propiedad 'posicion' con su nuevo valor respecto al índice que ocupa en el arreglo
 * @param {Array} arreglo Arreglo sobre el cual se realizará el cambio de posición
 * @param {number} indice Índice del arreglo que intercambiará posición con elemento previo (o anterior)
 */
const cambiarPosicionConElementoIndiceAnterior = (arreglo, indice) => {
  const elementoIndice = { ...arreglo[indice], posicion: indice - 1 };
  const elementoIndiceMenos1 = { ...arreglo[indice - 1], posicion: indice };
  arreglo[indice] = elementoIndiceMenos1;
  arreglo[indice - 1] = elementoIndice;
  return arreglo;
};

/**
 * actualiza la posición del elemento con id === id_elemento, dada la cumpleCondicionCambiarPosicionConElementoAnterior
 * @param {Array<Object>} elementos Arreglo de elementos firebase para cambiar posición
 * @param {string} id_elemento Id del elemento que intercambiará posición
 * @param {boolean} cumpleCondicionCambiarPosicionConElementoAnterior Expresión booleana que indicará si el elemento actual cambiará posición con el elemento anterior
 */
const cambiarPosicionElementos = (
  elementos,
  id_elemento,
  cumpleCondicionCambiarPosicionConElementoAnterior
) => {
  /* 
  const elementosFiltradosYOrdenados = elementos
    .sort((e1, e2) => {
      return (e1.posicion | 0) - (e2.posicion | 0);
    }); */
  const elementosFiltradosYOrdenados = elementos;
  const elementosConPosicionIntercambiada = elementosFiltradosYOrdenados.reduce(
    (resultado, elementoActual, indice, arreglo) => {
      // const elemento = { id: elementoActual.id, ...elementoActual };
      const elemento = {
        id: elementoActual.id,
        ...elementoActual,
        ref: elementoActual.ref,
        posicion: indice
      };
      if (
        cumpleCondicionCambiarPosicionConElementoAnterior(
          id_elemento,
          arreglo,
          indice
        )
      ) {
        resultado.elementos = cambiarPosicionConElementoIndiceAnterior(
          [...resultado.elementos, elemento],
          indice
        );
        resultado.pendienteActualizar[indice] = true;
        resultado.pendienteActualizar[indice - 1] = true;
        return resultado;
      }
      resultado.pendienteActualizar[indice] =
        elementoActual.posicion !== indice;
      resultado.elementos[indice] = { ...elemento, posicion: indice };
      return resultado;
    },
    { elementos: [], pendienteActualizar: [] }
  );
  return elementosConPosicionIntercambiada;
};

/**************************************************************************
 * funciones a exportar
 **************************************************************************/

export { ACCION, cambiarPosicionElementos, condicionParaCambiarPosicion };
