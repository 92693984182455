
/**
** Método global para formatear las fechas en el app
** se le pasa la fecha sin formatera como string y el formato al que se desea convertir
** @params (fecha a formatear en formato string, formato)
**/
export function setDateFormat(fecha, formato) {
    //fecha puede ir null para formatear la fecha actual
     let fecha_sin_formatear = new Date(fecha);
     //si se le pasa formato procesa la fecha
     //sino se le pasa formato retorna el objeto de la fecha que se pasa en el método
     if(formato === "YYYY-MM-DD") {
       return fecha_sin_formatear.getFullYear()+ "-" + ('0' + (fecha_sin_formatear.getMonth()+1)).slice(-2) + "-" + ('0' + fecha_sin_formatear.getDate()).slice(-2);
     } else if(formato === "YYYY-MM-DD h:mm") {
       return fecha_sin_formatear.getFullYear()+ "-" + ('0' + (fecha_sin_formatear.getMonth()+1)).slice(-2) + "-" + ('0' + fecha_sin_formatear.getDate()).slice(-2) + " " + fecha_sin_formatear.getHours() +":"+fecha_sin_formatear.getMinutes();
     } else if(formato === "YYYY-MM-DD hh:mm:ss") {
       return fecha_sin_formatear.getFullYear()+ "-" + ('0' + (fecha_sin_formatear.getMonth()+1)).slice(-2) + "-" + ('0' + fecha_sin_formatear.getDate()).slice(-2) + " " + fecha_sin_formatear.getHours() +":"+fecha_sin_formatear.getMinutes() +":"+fecha_sin_formatear.getSeconds();
     } else {
       return fecha_sin_formatear;
     }
}
