import {
  ACCION,
  cambiarPosicionElementosColeccionFirestore,
} from './FirestorePositionUpdate';

const arregloDeElementosDesdeReferenciaColeccionFirestore = (obj) => (
  (obj && obj.docs && obj.docs.length > 0)
  ? obj.docs : []
).map(doc => ({id: doc.id, ...doc.data(), ref: doc.ref}));

export const OrdenadorFirestore = {
  ACCION,
  arregloDeElementosDesdeReferenciaColeccionFirestore,
  cambiarPosicionElementosColeccionFirestore,
}