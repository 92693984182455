import {
    auth,
} from '../../firebase/firebase';

export function newAttributes() {
    if (auth.currentUser) {
        return {
            correo: auth.currentUser.email,
            nombres: auth.currentUser.displayName
        };
    } else {
        return false;
    }
}