module.exports = {
  api_key_google_maps: "AIzaSyAdE2BM1OHxUU8m3PJqfPjU7CYya7G4Zz4",
  api_key_firebase_admin: "AIzaSyAWpYm6jK0BBHfuzb3BXfOv5VIL1Ud2JHc",
  auth_domain_admin: "admin-cemtrik.firebaseapp.com",
  projectId_admin: "admin-cemtrik",
  storageBucket_admin: "admin-cemtrik.appspot.com",
  messagingSenderId: "895380609398",
  appId: "1:895380609398:web:d9781441ca482aba2b4257",
  measurementId: "G-QRS3P22NHR",
  api_key_firebase_worldDB: "AIzaSyDn06RmDXwmOe5ndYLRCLcdFytZPOsNufo",
  auth_domain_worldDB: "worlddb-cemtrik.firebaseapp.com",
  projectId_worldDB: "worlddb-cemtrik",
  format_date: "YYYY-MM-DD",
  format_date_time: "YYYY-MM-DD hh:mm:ss",
  url_feedback: "https://v2.forms.cemtrik.com/",
  url_api_admin_prod: "https://us-central1-admin-cemtrik.cloudfunctions.net",
  privateEndPoint: "https://private-api-bfbyhvue.uc.gateway.dev",
  api_key_admin: "2qVIRedzkFK26NT7OP4i"
};
