module.exports = {
  api_key_google_maps: "AIzaSyCbDozACbPruH6yRdem_iv8PByd2eZ8Z-8",
  api_key_firebase_admin: "AIzaSyBKGaPFRkAtldC9XMe39vBx7I_AVQSpGjA",
  auth_domain_admin: "admin-dev-cemtrik.firebaseapp.com",
  projectId_admin: "admin-dev-cemtrik",
  storageBucket_admin: "admin-dev-cemtrik.appspot.com",
  messagingSenderId: "57849133671",
  appId: "1:57849133671:web:caeab134a54c20e55532c2",
  measurementId: "G-5YV7VZPNH4",
  api_key_firebase_worldDB: "AIzaSyDn06RmDXwmOe5ndYLRCLcdFytZPOsNufo",
  auth_domain_worldDB: "worlddb-cemtrik.firebaseapp.com",
  projectId_worldDB: "worlddb-cemtrik",
  format_date: "YYYY-MM-DD",
  format_date_time: "YYYY-MM-DD hh:mm:ss",
  url_feedback: "https://forms-ssr-dev.web.app/",
  url_api_admin_prod:
    "https://us-central1-admin-dev-cemtrik.cloudfunctions.net",
  privateEndPoint: "https://private-api-qkpvp7r.uc.gateway.dev",
  api_key_admin: "2qVIRedzkFK26NT7OP4i"
};
