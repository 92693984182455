import React from "react";
import { NavLink } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "actions/Auth";
import IntlMessages from "util/IntlMessages";
import AvatarNoUser from "../../assets/images/avatar_no_user.png";
import { auth, firestore } from "../../firebase/firebase";

class UserInfo extends React.Component {
  state = {
    nombre: "",
    photoURL: "",
    telefono: "",
    anchorEl: null,
    open: false
  };

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      this.userAuth(user.uid);
    });
  }

  userAuth(id) {
    let docRef = firestore.collection("usuarios").doc(id);
    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          if ((doc.data().nombres + " " + doc.data().apellidos).length < 17) {
            this.setState({
              nombre: doc.data().nombres + " " + doc.data().apellidos,
              photoURL: doc.data().imagenUrl
            });
          } else {
            let strng = doc.data().apellidos;
            let cadena = strng.slice(0, -strng.length + 1);
            this.setState({
              nombre: doc.data().nombres + " " + cadena + ".",
              photoURL: doc.data().imagenUrl
            });
          }
        } else {
          this.setState({
            nombre: "Usuario",
            photoURL: AvatarNoUser
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar alt="..." src={this.state.photoURL} className="user-avatar " />
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>
            {this.state.nombre}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
        >
          <MenuItem onClick={this.handleRequestClose}>
            <NavLink className="prepend-icon" to={"/app/perfiles/perfil/"}>
              <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
              <IntlMessages id="Perfil" />
            </NavLink>
          </MenuItem>
          <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
            <IntlMessages id="Configuración" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              this.props.userSignOut();
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="Salir" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);
